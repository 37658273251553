.header,
.linha {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.header div,
.linha div {
  border: 1px solid #ccc;
  padding: 8px;
}
