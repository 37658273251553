.options-container {
  display: flex;
  flex-wrap: wrap;
}

.option-button {
  width: 100%;
  margin: 5px;
  border-radius: 15px;
  padding: 14px;
  border: none;
  background: transparent;
  text-align: center;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  color: #150442;
  transition: 0.3s;
  background: #ffffff;
  box-shadow: 5px 5px 31px #cccccc, -5px -5px 31px #ffffff;
}

.react-chatbot-kit-chat-bot-avatar-container {
  background-image: url("./JoannaIcon.jpeg");
  background-color: transparent;
  background-size: cover;
}

.react-chatbot-kit-chat-bot-avatar-letter {
  display: none;
}

.option-button:hover {
  scale: 1.05;
}

.option-button:hover {
  cursor: pointer;
}

/* img {
  width: 100%;
  border-radius: 10%;
} */

.react-chatbot-kit-chat-bot-message {
  background-color: #5b0390;
  margin-left: 0px;
}
.react-chatbot-kit-chat-bot-message-arrow {
  border-right-color: #5b0390;
}
